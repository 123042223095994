// Fonts
// ----------
$font-size-base: 16px;
$line-height-base: 19px;
$font-family: 'proxima_nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Text Colors
// ----------
$color-black: #000;
$color-white: #fff;
$color-active: #0083e3;
$color-muted: #a7aaae;
$color-default: #1a1919;
$color-disabled: #bbbbbb;
$color-light-blue: #718fcd;
$color-blue: #67b0c0;
$color-light-grey: #a1a1a1;
$color-grey: #7e7e7e;
$color-menu: #e9f4fc;
$color-positive: #2eb6a3;
$color-neutral: #dbdbe2;
$color-warning: #e5dfa2;
$color-yellow: #ffffef;
$color-error: #9b4c49;

// Layout
// ----------
$gutter: 1.5rem;
$gutter-double: 3rem;
$nav-height: 60px;

// Background Colors
// ----------
$bg-color-email: #eff1f5;
$bg-color-disabled: #f1f2f2;
$bg-color-positive: #18a4ab;
$bg-color-positive-dark: darken($bg-color-positive, 5%);
$bg-color-positive-darker: darken($bg-color-positive, 15%);
$bg-color-neutral: $color-neutral;
$bg-color-neutral-alt: #c0c5cc;
$bg-color-blue: #67b0c0;
$bg-color-blue-dark: #5692a0;
$bg-color-blue-darker: #314f5f;

// Box Shadow
// ----------
$box-shadow-divider: inset 10px 0 60px -20px rgba($color-black, 0.2),
  inset 10px 0 20px -10px rgba($color-black, 0.1);
$box-shadow-card: -2px 2px 4px rgba(0, 0, 0, 0.1);

// Responsive Breakpoints
// ----------
// Based on Semantic-UI breakpoints - https://semantic-ui.com/elements/container.html
$display-width-tablet: 768px;
$display-width-small: 992px;
$display-width-large: 1200px;

// From BW
$font-family: 'proxima_nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-size-base: 14px;
$normal: 400;
$light-bold: 500;
$semi-bold: 600;
$bold: 700;

$color-default: #002240;
$color-white: #fff;
$color-black: #000;
$color-dark-grey: #537089;
$color-dark-grey-alt: #999dac;
$color-blue: #0688fa;
$color-disabled: #a8acaf;
$color-shy-grey: #e1daf2;
$color-light-blue: #e9f4fd;
$color-neutral-light: #e1eaf2;
$color-red: #c9604b;
$color-bright-red: #db2828;
$color-active-dark: #2756f1;
$color-sky-blue: #82c4fd;

$bg-color-white: $color-white;
$bg-color-primary: $color-blue;
$bg-color-disabled: #e8e9ea;
$bg-color-error: #efd9d9;
$bg-color-table-header: #eff1f5;
$bg-color-integrations: #f2f3f6;
$bg-color-pane: #f8f8f8;

$border-color-card: #dcdcdc;
$border-color-blue: $color-blue;
$border-color-disabled: #bdbdbd;
$border-color-info: #3a8dd1;
$border-color-error: #ad4040;
$border-color-mid-grey: #b4c3d0;

$bg-color-white: $color-white;
$bg-color-header: #0468b8;

$bg-color-positive-new: #18a4ab;
$bg-color-positive-new-darker: #1b8d93;
$bg-color-positive-new-dark: darken($bg-color-positive-new, 15%);

$label-red: #ad4040;
$label-orange: #ff8533;
$label-yellow: #ffaa04;
$label-green: #389c67;
$label-blue: #3a8dd1;
$label-purple: #5c6ae5;
$label-neutral: #002240;
$label-light-green: #d7ebe1;
$label-light-red: #efd9d9;
$label-light-blue: #d8e8f6;
$label-light-yellow: #ffeecd;
$label-light-purple: #e1e4ff;
$label-light-neutral: #d3d5d7;
$label-light-orange: #ffe4d8;

$box-shadow-card: 0px 4px 4px rgba(0, 0, 0, 0.05);

$border-radius-default: 8px;

$z-index-layer-base: 100;
$z-index-layer-one: 101;
$z-index-layer-two: 102;
$z-index-layer-three: 103;
$z-index-highest: 1100;

$display-tablet: 768px;
$display-desktop-large: 1127px;
$display-desktop-xlarge: 1367px;

$nav-height: 60px;
$isolated-header-height: 64px;

$close-panel-height: 100px;
