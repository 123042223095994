.Container {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 200px;
  height: 200vh;
  h1,
  p {
    margin: 0;
  }
}
