@import '../../assets/styles/variables.scss';

.Container {
  padding: 0 64px;
  overflow: auto;
}
.Subject {
  padding-bottom: 18px;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 24px;
  color: #252f35;
  display: block;
}
.Card {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  padding: 14px 22px 16px 18px;
  border: 2px solid transparent;
  margin-bottom: 12px;
}
.Meta {
  display: flex;
  padding-bottom: 17px;
  margin-bottom: 19px;
  border-bottom: 1px solid #e9eef3;
}
.Body {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
  padding: 14px 22px 16px 18px;
}
.Detail {
  margin-left: 10px;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 128%;
  color: #000;
}
.ParsedBody {
  border: 1px solid $color-blue;
  border-radius: 4px;
  padding: 2px;
  white-space: pre-line;
}
